// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {YkiRknEm1: {hover: true, pressed: true}};

const cycleOrder = ["YkiRknEm1"];

const variantClassNames = {YkiRknEm1: "framer-v-l03w2g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "YkiRknEm1", title: VTvS6_Bhh = "Button", link: uHRoXhdu7, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YkiRknEm1", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZfQbE", classNames)} style={{display: "contents"}}>
<Link href={uHRoXhdu7}><motion.a {...restProps} className={`${cx("framer-l03w2g", className)} framer-u2pfvy`} data-framer-name={"Button"} layoutDependency={layoutDependency} layoutId={"YkiRknEm1"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"YkiRknEm1-hover": {"data-framer-name": undefined}, "YkiRknEm1-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "22px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Button</motion.p></React.Fragment>} className={"framer-1ci61kw"} data-framer-name={"Join Now for $99/Year"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"qQz0uQbV_"} style={{"--extracted-r6o4lv": "rgb(5, 104, 57)", "--framer-paragraph-spacing": "0px"}} text={VTvS6_Bhh} transition={transition} variants={{"YkiRknEm1-hover": {"--extracted-r6o4lv": "rgb(4, 78, 43)"}, "YkiRknEm1-pressed": {"--extracted-r6o4lv": "rgb(3, 53, 30)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZfQbE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZfQbE .framer-u2pfvy { display: block; }", ".framer-ZfQbE .framer-l03w2g { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-ZfQbE .framer-1ci61kw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-ZfQbE .framer-v-l03w2g .framer-l03w2g { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZfQbE .framer-l03w2g { gap: 0px; } .framer-ZfQbE .framer-l03w2g > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-ZfQbE .framer-l03w2g > :first-child { margin-top: 0px; } .framer-ZfQbE .framer-l03w2g > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 67
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"gPMftQami":{"layout":["auto","auto"]},"OFTgF2BFf":{"layout":["auto","auto"]}}}
 * @framerVariables {"VTvS6_Bhh":"title","uHRoXhdu7":"link"}
 */
const FramerGizzOia3I: React.ComponentType<Props> = withCSS(Component, css, "framer-ZfQbE") as typeof Component;
export default FramerGizzOia3I;

FramerGizzOia3I.displayName = "Button – Ghost";

FramerGizzOia3I.defaultProps = {height: 28, width: 67};

addPropertyControls(FramerGizzOia3I, {VTvS6_Bhh: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}, uHRoXhdu7: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerGizzOia3I, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/GizzOia3I:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])